import Navbar from "./Navbar"
import Home from "./pages/Home"
import About from "./pages/About"
import CompoundInterest from "./pages/CompoundInterest"
import { Route, Routes } from "react-router-dom"

function App() {
  return (
    <>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/compoundinterest" element={<CompoundInterest />} />
        </Routes>
      </div>
    </>
  )
}

export default App
