//import "./App.css";
import { useState } from "react";

function CompoundInterest() {
  const [principal, setPrincipal] = useState("");
  const [rate, setRate] = useState("");
  const [period, setPeriod] = useState("");
  const [message, setMessage] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('calling api GET');
      const res = await fetch("https://8gj2wv62of.execute-api.us-east-1.amazonaws.com/prod/compoundinterestcalc?principal="+principal+"&rate="+rate+"&num_years="+period+"");
      const resJson = await res.json();
      console.log(resJson);

      if (res.status === 200) {
        //setPrincipal("");
        //setRate("");
        //setPeriod("");
        setMessage("Total amount: "+resJson.total_amount);
      } else {
        console.log(res.status);
        setMessage("Ah-uh..some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="CompoundInterest">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={principal}
          placeholder="Principal (I have this money now)"
          onChange={(e) => setPrincipal(e.target.value)}
        />
        <input
          type="text"
          value={rate}
          placeholder="Interest rate (money grows this %)"
          onChange={(e) => setRate(e.target.value)}
        />
        <input
          type="text"
          value={period}
          placeholder="No: of years (money remains invested)"
          onChange={(e) => setPeriod(e.target.value)}
        />

        <button type="submit">Get Total Amount</button>

        <div className="message">{message ? <p>{message}</p> : null}</div>
      </form>
    </div>
  );
}

export default CompoundInterest;
